<template>
  <div id="program-applications-body">
    <div class="card rounded shadow-sm">
      <div class="card-body">
        <div class="col-4 px-0 mb-3">
          <div class="input-group">
            <input
              v-model="searchValue"
              type="text"
              class="form-control"
              placeholder="Search HEI or program name here... "
            />
          </div>
        </div>
        <div v-if="loader" class="d-flex align-items-center pb-3">
          <div class="spinner-border spinner-border-sm text-primary mr-2"></div>
          <strong class="text-primary"
            >Retrieving program applications...</strong
          >
        </div>
        <table
          id="program-apps"
          class="table table-hover table-bordered table-sm"
        >
          <thead>
            <th>APP NO</th>

            <th>INSTITUTION NAME</th>
            <th>PROGRAM</th>
            <th>TYPE</th>
            <th>ACADEMIC YEAR</th>
            <th>SUBMITED AT</th>
          </thead>
          <tbody>
            <tr
              v-for="app in filteredApplications"
              :key="app.id"
              @click.stop="viewApplication(app)"
            >
              <th>PA-{{ app.id }}-2023</th>

              <td>{{ app.name }}</td>
              <td>{{ app.program_name }}</td>
              <td>{{ app.application_type }}</td>
              <td>{{ app.academic_year }}</td>

              <td>{{ app.created_at | completeDateTime }}</td>
              <!--    <td>
                <div class="btn-group">
                  <button
                    @click.prevent="viewApplication(app)"
                    class="btn btn-outline-secondary btn-sm"
                  >
                    <i class="bi bi-folder2-open text-primary"></i>
                  </button>

                  <button class="btn btn-outline-secondary btn-sm">
                    <i class="bi bi-trash text-danger"></i>
                  </button>
                </div>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Start of View Application Modal -->
    <div class="modal fade" id="view-application-modal">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col-4 overflow-auto" style="max-height: 900px;">
                <table class="table table-bordered table-sm ">
                  <tbody>
                    <tr class="text-center">
                      <td colspan="2" class="font-weight-bold">
                        APPLICATION DETAILS
                      </td>
                    </tr>
                    <tr>
                      <td width="45%">Application No.</td>
                      <td class="font-weight-bold">
                        PA-{{ application.id }}-2023
                      </td>
                    </tr>
                    <tr>
                      <td>Name of Institution</td>
                      <td class="font-weight-bold">{{ application.name }}</td>
                    </tr>
                    <tr>
                      <td>Program being applied</td>
                      <td class="font-weight-bold">
                        {{ application.program_name }}
                      </td>
                    </tr>
                    <tr>
                      <td>Application Type</td>
                      <th>
                        {{ application.application_type }}
                      </th>
                    </tr>
                    <tr>
                      <td>Academic Year</td>
                      <th>
                        {{ application.academic_year }}
                      </th>
                    </tr>

                    <tr>
                      <td>Submitted on</td>
                      <td class="font-weight-bold">
                        {{ application.created_at | dateTime }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-sm table-bordered">
                  <tr>
                    <th colspan="2">
                      REQUIRED DOCUMENTS
                    </th>
                  </tr>
                  <tr v-for="(req, index) in requirements" :key="req.id">
                    <td style="white-space: pre-line;" width="60%">
                      {{ index + 1 }}. {{ req.description }}
                    </td>
                    <td>
                      <a href="#" @click.prevent="previewFile(req)">
                        <i class="bi bi-file-pdf mr-1"></i>
                        {{ req.filename }}</a
                      >
                      <small class="form-text">
                        {{ req.created_at | completeDateTime }}</small
                      >
                    </td>
                  </tr>
                </table>
              </div>
              <div class="col">
                <iframe
                  :src="src"
                  width="100%"
                  height="100%"
                  allow="autoplay"
                  class="rounded"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End of View Application Modal -->
  </div>
</template>

<script>
export default {
  name: 'ProgramApplications',
  components: {},
  data() {
    return {
      modalLoader: false,
      loader: false,
      searchValue: '',
      apps: '',
      attachments: '',
      requirements: [],
      application: new Form({
        id: '',
        name: '',
        program_name: '',
        application_type: '',
        academic_year: '',
        created_at: '',
      }),
      prefix: 'https://drive.google.com/file/d/',
      src: '',
    };
  },
  methods: {
    getRequirements(id) {
      this.modalLoader = true;
      axios
        .get('api/program-applications/files/' + this.application.id)
        .then((response) => {
          this.requirements = response.data;
          this.modalLoader = false;
          this.previewFile(this.requirements[0]);
        })
        .catch((error) => {
          this.modalLoader = false;
          toastr.error(error.response.data.message, 'Error');
        });
    },
    previewFile(req) {
      this.src = `${this.prefix}${req.path}/preview`;
    },
    viewApplication(application) {
      console.log(application);
      $('#view-application-modal').modal('show');
      this.application.fill(application);
      this.getRequirements();
    },
    getApplications() {
      this.loader = true;

      axios
        .get('api/program-applications')
        .then((response) => {
          this.apps = response.data;

          this.loader = false;
        })
        .catch((error) => {
          toastr.error('Something went wrong. Please try again.', 'Error');
          this.loader = false;
        });
    },
  },
  computed: {
    filteredApplications() {
      let tempApps = this.apps;

      if (this.searchValue != '') {
        tempApps = tempApps.filter((app) => {
          return (
            app.name.toUpperCase().includes(this.searchValue.toUpperCase()) ||
            app.program_name
              .toUpperCase()
              .includes(this.searchValue.toUpperCase())
          );
        });
      }
      return tempApps;
    },
  },
  mounted() {
    this.getApplications();
  },
};
</script>

<style scoped>
#program-apps tr {
  cursor: pointer !important;
}
</style>
